import React from 'react';
import {graphql} from 'gatsby';
import Category from '../../templates/category';

import ContactForm from '../../components/ContactForm';

const Page = ({data}) => {
  const excerpt = ``;

  return (
    <>
      <Category
        title='Contactez nous'
        slug='contact'
        excerpt={excerpt}
        img=''
      >
        <ContactForm />
      </Category>
    </>
  );
};

export default Page;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
